import { AttachmentType } from '@playq/services-beetle';
import { OffsetLimit } from '@playq/services-shared';

export const downloadAttachment = (attachmentURL: string, name: string, type: AttachmentType) => () => {
  const link = document.createElement('a');
  link.download = name;
  link.type = type;
  link.href = attachmentURL;
  link.target = '_blank';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const goToAttacmentURL = (url: string) => {
  window.open(url, '_blank')?.focus();
};

export const calculateTotalAttachment = (
  nextOffsetLimit: OffsetLimit | undefined,
  currentLength: number,
  currentIterator: OffsetLimit,
  prefetch: number
): number => {
  if (currentLength === 0) return 0;

  const serialized = nextOffsetLimit?.serialize();

  if (!serialized) return currentLength;

  const hasMoreItems: boolean = Boolean(prefetch) && currentLength === currentIterator.limit;

  const baseCount = hasMoreItems ? serialized.offset + serialized.limit : serialized.offset;

  return baseCount + currentLength - currentIterator.limit;
};
