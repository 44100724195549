import { ZipReader, BlobReader, TextWriter } from '@zip.js/zip.js';

export const fetchAndExtractZip = async ({
  url,
  name,
  cb,
}: {
  url: string;
  name: string;
  cb: (prop: { text: string; name: string }) => void;
}) => {
  try {
    cb({ text: '', name: name });
    const response = await fetch(url, {
      method: 'GET',
    });

    if (!response.ok) {
      throw new Error('Failed to fetch the file');
    }

    const blob = await response.blob();

    const reader = new ZipReader(new BlobReader(blob));

    const entries = await reader.getEntries();

    for (const entry of entries) {
      if (!entry.directory) {
        if (entry.getData) {
          const text = await entry.getData(new TextWriter());
          cb({ text, name });
        }
      }
    }

    reader.close();
  } catch (error) {
    console.error('Error extracting ZIP:', error);
  }
};
